import * as React from "react";
import {FC, useEffect, useState} from "react";
import Layout from "../../components/Navigation/layout";
import * as yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {Alert, Button, Col, Form, Row} from "react-bootstrap";
import * as style from './index.module.scss';
import * as mainStyle from '../../theme/main.module.scss';
import {StaticImage} from "gatsby-plugin-image";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faKey} from "@fortawesome/pro-light-svg-icons";
import {Client, handleApiError} from "../../services/ApiService";
import {toast} from "react-toastify";
import {setBasketAmount, setBasketID} from "../../stores/basket/basketSlice";
import {setKey} from "../../stores/auth/authSlice";
import {setMember, setMemberID, setVendorID} from "../../stores/member/memberSlice";
import {connect, ConnectedProps} from "react-redux";
import {navigate} from "gatsby";

const mapState = ({auth, basket}) => ({
    auth,
    basket,
})

const mapDispatch = {
    setKey,
    setBasketID,
    setMember,
    setMemberID,
    setVendorID,
    setBasketAmount
}

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>

interface FormInputs {
    Password: string,
    PasswordRetype: string,
}

const schema = yup.object().shape({
    Password: yup.string().required('Bitte gib ein Passwort ein.').min(8, 'Das Passwort muss mindestestens 8 Zeichen lang sein.'),
    PasswordRetype: yup.string().required('Bitte wiederhole das Passwort.').oneOf([yup.ref('Password'), null], 'Die Passwörter stimmen nicht überein.'),
})

const IndexPage: ({
                      setKey,
                      setBasketID,
                      setMemberID,
                      setMember,
                      setVendorID,
                      basket
                  }: { setKey: any; setBasketID: any; setMemberID: any; setMember: any; setVendorID: any; basket: any }) => JSX.Element = ({setKey, setBasketID, setMemberID, setMember, setVendorID, basket}) => {
    const [hash, setHash] = useState('');
    const [pwr, setPwr] = useState({});
    const [error, setError] = useState('');
    const {register, handleSubmit, formState: {errors}} = useForm<FormInputs>({
        resolver: yupResolver(schema)
    });

    useEffect(() => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const hash = urlParams.get('hash')
        setHash(hash);

        if (hash) {
            Client.Raw.get("/PasswordResetRequest/?filter[Hash]=" + hash)
                .then(result => {
                    if (typeof result.data[0] !== 'undefined' && result.data[0].Done !== 1) {
                        setPwr(result.data[0]);
                        setError('');
                    } else {
                        setError('Kein gültiger Passwort zurücksetzen Link');
                    }
                }).catch(handleApiError)
        } else {
            setError('Kein gültiger Passwort zurücksetzen Link');
        }
    }, [])

    function onSubmit(data: FormInputs) {
        Client.Raw.post('PasswordResetRequest/setpassword/' + pwr.Hash, {
            Hash: pwr.Hash,
            Password: data.Password
        }).then((resAuth) => {
            if (resAuth.data.valid === false) {
                // toast.error(resAuth.data.message);
            } else {
                toast.success('Dein Passwort wurde erfolgreich geändert!');
                setKey(resAuth.data.Key);
                setMemberID(resAuth.data.MemberID);
                Client.Raw.get('Member/' + resAuth.data.MemberID).then((res) => {
                    setVendorID(res.data.VendorID);
                    setMember(res.data);
                }).catch(handleApiError)
                Client.Basket.fetchAuthorized(resAuth.data.Key, basket.BasketID ? basket.BasketID : '').then((res) => {
                    setBasketID(res.data.ID);
                    setBasketAmount(res.data.TotalItems);
                    navigate('/user');
                }).catch(handleApiError);
            }
        })
    }

    return (
        <Layout pageTitle="Passwort zurücksetzen">
            <div className={style.loginBanner}>
                <div className="container h-100 d-flex flex-column">
                    <Row className="justify-content-center align-items-center flex-grow-1">
                        <Col lg={{span: 12}}>
                            <h2 className={'text-center mb-5 ' + style.heading}>Passwort vergessen?</h2>
                            {error ? <Alert variant={"danger"}>{error}</Alert>
                                : <Form onSubmit={handleSubmit(onSubmit)}>
                                    <Row>
                                        <Col lg={12}>
                                            <Form.Group controlId="formPassword">
                                                <Form.Label
                                                    className={style.inputLabelBasic}> Passwort*</Form.Label>
                                                <Form.Control type="password"
                                                              className={style.inputFieldBasic} {...register('Password')}/>
                                                <Form.Text className={mainStyle.errorMsg}>
                                                    {errors.Password?.message}
                                                </Form.Text>
                                            </Form.Group>
                                        </Col>
                                        <Col lg={12}>
                                            <Form.Group controlId="formPasswordRetype">
                                                <Form.Label className={style.inputLabelBasic}> Passwort
                                                    wiederholen*</Form.Label>
                                                <Form.Control type="password"
                                                              className={style.inputFieldBasic} {...register('PasswordRetype')}/>
                                                <Form.Text className={mainStyle.errorMsg}>
                                                    {errors.PasswordRetype?.message}
                                                </Form.Text>
                                            </Form.Group>
                                        </Col>
                                        <Col lg={12}>
                                            <Button type={"submit"} className={style.submitButtonBasic}>
                                                <FontAwesomeIcon icon={faKey} className={style.icon}/>
                                                Passwort ändern
                                            </Button>
                                        </Col>
                                    </Row>
                                </Form>
                            }
                        </Col>
                    </Row>
                </div>
            </div>
        </Layout>
    )
}

export default connector(IndexPage);
